/* eslint-disable no-nested-ternary */
import React from "react"
import { string, shape } from "prop-types"
import styled from "@emotion/styled"
import AspectRatio from "./aspect-ratio"

const propTypes = {
  src: string.isRequired,
  fallbackFluid: shape(),
  alt: string,
}

const defaultProps = {
  fallbackFluid: null,
  alt: "",
}

const ImageWrapper = styled.div`
  background-color: ${props => props.theme.colours.white};
  padding: 1em;
  margin-bottom: 1em;
`
const StyledAspectRatio = styled(AspectRatio)`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const StyledImage = styled.img`
  width: 100%;
  margin: 0;
  height: auto;
`

const BrandImage = ({ src, fallbackFluid, alt }) => {
  return (
    <ImageWrapper>
      <StyledAspectRatio width="1" height="1">
        <div>
          {src ? (
            <StyledImage itemProp="image" src={src} alt={alt} />
          ) : fallbackFluid ? (
            <StyledImage itemProp="image" alt={alt} />
          ) : null}
        </div>
      </StyledAspectRatio>
    </ImageWrapper>
  )
}

BrandImage.propTypes = propTypes

BrandImage.defaultProps = defaultProps

export default BrandImage
