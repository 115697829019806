import React from "react"
import { array, shape, string } from "prop-types"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Flex, Box } from "rebass"
import styled from "@emotion/styled"
import Fade from "react-reveal/Fade"
import ProductImage from "../components/atoms/product-image"
import BrandImage from "../components/atoms/brand-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Heading2 from "../components/atoms/heading-2"
import RichText from "../components/molecules/rich-text"
import theme from "../config/theme"
import ProductInquiryForm from "../components/molecules/product-inquiry-form"
import ProductsHeader from "../components/molecules/products-header"
import RelatedItems from "../components/molecules/related-items"
import ProductInformationList from "../components/molecules/product-information-list"

const propTypes = {
  data: shape({
    contentfulProduct: shape({
      title: string,
      slug: string,
      body: shape({
        body: string,
      }),
      model: string,
      measurements: shape({
        measurements: string,
      }),
      connections: shape({
        connections: string,
      }),
      code: string,
      additionalInformation: shape({
        additionalInformation: string,
      }),
      weight: string,
      image: shape({
        file: shape({
          url: string,
        }),
      }),
      categories: array,
    }).isRequired,
  }).isRequired,
  pageContext: shape({
    languages: shape({
      en: string,
      fi: string,
    }).isRequired,
  }).isRequired,
  path: string.isRequired,
  intl: shape().isRequired,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
}

const ProductTitle = styled(Heading2)`
  font-size: 90%;
  margin-bottom: 1.5em;
  word-break: break-word;
  @media ${props => props.theme.breakpoint.desktop} {
    font-size: 155% !important;
  }
`

const InnerBox = styled.div`
  @media ${props => props.theme.breakpoint.desktop} {
    max-width: 92%;
  }
`
const ProductInformationWrapper = styled(InnerBox)`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`
const ProductInformation = styled.div`
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  /* flex-direction: column; */
  width: 100%;
`
const BrandLink = styled(Link)`
  display: inline-block;
  /* align-self: flex-end; */
  width: 30%;
  margin-left: auto;
  max-width: 300px;
`

const Product = ({ intl, data, pageContext, path, location }) => {
  const { locale } = intl
  const { languages: slugs } = pageContext
  const {
    title,
    body,
    model,
    measurements,
    connections,
    code,
    additionalInformation,
    weight,
    image,
    categories,
    slug,
  } = data.contentfulProduct
  const { edges: productsFromCategory } = data.relatedProducts
  const relatedItems = productsFromCategory.filter(p => {
    if (p.node.slug !== slug && p.node.node_locale === locale && p.node.title) {
      return p
    }
    return false
  })

  const fluid = data.productImage ? data.productImage.fluid : null
  const brand = data.brandImage ? data.brandImage.brand : null

  const breadcrumbs = []
  breadcrumbs.push({
    title: intl.formatMessage({ id: "catalogue" }),
    link: intl.formatMessage({ id: "cat_path" }),
  })
  if (categories) {
    breadcrumbs.push({
      title: categories[0].title,
      link: `${intl.formatMessage({ id: "cat_path" })}/${
        pageContext.category_slug
      }/${categories[0].slug}`,
    })
  }
  breadcrumbs.push({
    title,
    link: null,
  })

  // const fallbackImage = data.contentfulAsset ? data.contentfulAsset : null
  return (
    <Layout
      hasProductsHeader
      slugs={slugs}
      colourTheme="mercury"
      currentPath={path}
    >
      <SEO title={title} location={location.pathname} />
      <ProductsHeader breadcrumbs={breadcrumbs} />
      <Fade bottom distance="3em" delay={300} duration={1500}>
        <Flex
          flexWrap="wrap"
          mx={theme.flexGutter}
          itemType="http://schema.org/Thing"
          itemScope
          mb={[0, 0, 0, 0, 5]}
        >
          <Box
            width={[1, 1, 1 / 2, 1 / 2, 1 / 3]}
            px={theme.boxGutter}
            mb={[0, 4, 4, 4, 0]}
          >
            <InnerBox>
              {image && (
                <ProductImage fluid={fluid} title={title} itemProp="image" />
              )}
            </InnerBox>
          </Box>
          <Box
            width={[1, 1, 1 / 2, 1 / 2, 1 / 3]}
            px={theme.boxGutter}
            mb={[0, 4, 4, 4, 0]}
          >
            <ProductInformationWrapper>
              <div>
                <ProductTitle as="h1" itemProp="name">
                  {title}
                </ProductTitle>
                {body && (
                  <RichText type="small" data={body} itemProp="description" />
                )}
              </div>
              <ProductInformation>
                <ProductInformationList
                  model={model}
                  measurements={measurements}
                  connections={connections}
                  code={code}
                  additionalInformation={additionalInformation}
                  weight={weight}
                />
                {brand && (
                  <BrandLink
                    to={`${intl.formatMessage({ id: "brand_path" })}/${
                      brand.slug
                    }`}
                    itemProp="brand"
                    itemType="http://schema.org/Thing"
                    itemScope
                  >
                    {brand.logo ? (
                      <data itemProp="brand name">
                        <BrandImage
                          src={brand.logo.fluid.src}
                          alt={brand.title}
                          itemProp="image"
                          content={brand.title}
                        />
                      </data>
                    ) : (
                      <>{brand.title}</>
                    )}
                  </BrandLink>
                )}
                {/* {categories && categories.map(c => <span key={c.id}>{c.title}</span>)} */}
              </ProductInformation>
            </ProductInformationWrapper>
          </Box>
          <Box width={[1, 1, 1, 1, 1 / 3]} px={theme.boxGutter}>
            <InnerBox>
              <ProductInquiryForm product={title} />
            </InnerBox>
          </Box>
        </Flex>
      </Fade>
      {relatedItems.length > 0 && (
        <Fade bottom distance="3em" delay={150} duration={1500}>
          <RelatedItems allItems={productsFromCategory} items={relatedItems} />
        </Fade>
      )}
    </Layout>
  )
}

Product.propTypes = propTypes

export default injectIntl(Product)

export const pageQuery = graphql`
  query(
    $id: String
    $image_id: String
    $locale: String
    $brand_name: String
    $subcategory_contentful_id: String
  ) {
    productImage: contentfulAsset(contentful_id: { eq: $image_id }) {
      title
      fluid(maxWidth: 768, maxHeight: 768) {
        ...GatsbyContentfulFluid
      }
    }
    brandImage: contentfulProduct(
      brand: { title: { eq: $brand_name }, node_locale: { eq: "fi" } }
    ) {
      brand {
        title
        homepage
        slug
        logo {
          fluid(maxWidth: 300) {
            src
          }
        }
      }
    }
    contentfulProduct(id: { eq: $id }, node_locale: { eq: $locale }) {
      title
      slug
      body {
        ... on contentfulProductBodyRichTextNode {
          id
          json
        }
      }
      model
      measurements {
        measurements
      }
      connections {
        connections
      }
      code
      additionalInformation {
        additionalInformation
      }
      weight
      image {
        fluid(maxWidth: 768, maxHeight: 768) {
          ...GatsbyContentfulFluid
        }
      }
      categories {
        id
        title
        slug
      }
    }
    relatedProducts: allContentfulProduct(
      filter: {
        categories: {
          elemMatch: { contentful_id: { eq: $subcategory_contentful_id } }
        }
      }
    ) {
      edges {
        node {
          id
          contentful_id
          title
          slug
          node_locale
          body {
            ... on contentfulProductBodyRichTextNode {
              id
              json
            }
          }
          image {
            fluid(maxWidth: 768, maxHeight: 768) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
