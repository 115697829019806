import React, { useState } from "react"
import { shape, string } from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import { navigate } from "gatsby-link"
import { useForm } from "react-hook-form"
import styled from "@emotion/styled"
import { Text, Box } from "rebass"
import Heading2 from "../atoms/heading-2"
import InputText from "../atoms/input-text"
import TextArea from "../atoms/text-area"
import FormButton from "../atoms/form-button"
import ErrorMessage from "../atoms/form-error-message"

const propTypes = {
  intl: shape().isRequired,
  product: string.isRequired,
}

const StyledBox = styled(Box)`
  margin-bottom: 0.5em;
`
const ContactFormTitle = styled(Heading2)``

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&")
}

const ProductInquiryForm = ({ intl, product }) => {
  const { locale } = intl

  const [state, setState] = useState({
    "product-of-interest": product,
    language: locale,
  })

  const { register, handleSubmit, errors } = useForm()

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = (data, e) => {
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }

  return (
    <div>
      <ContactFormTitle>
        {intl.formatMessage({ id: "contact.interested" })}
        <br />
        {intl.formatMessage({ id: "contact.contact_us" })}
      </ContactFormTitle>
      <form
        name="product_inquiry_form"
        method="post"
        action={`/${locale}/thanks`}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="product_inquiry_form" />
        <input type="hidden" name="language" readOnly />
        <input type="hidden" readOnly name="product-of-interest" />
        <p hidden>
          <input name="bot-field" onChange={handleChange} />
        </p>
        <StyledBox>
          <InputText
            placeholder={`${intl.formatMessage({ id: "contact.name" })}*:`}
            type="text"
            name="name"
            aria-label={intl.formatMessage({ id: "contact.name" })}
            onChange={handleChange}
            forwardRef={register({ required: true })}
          />
          {errors.name && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.name" })}
            />
          )}
        </StyledBox>
        <StyledBox>
          <InputText
            placeholder={`${intl.formatMessage({ id: "contact.email" })}*:`}
            type="email"
            name="email"
            aria-label={intl.formatMessage({ id: "contact.email" })}
            onChange={handleChange}
            forwardRef={register({ required: true })}
          />
          {errors.email && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.email" })}
            />
          )}
        </StyledBox>
        <StyledBox>
          <InputText
            placeholder={`${intl.formatMessage({ id: "contact.company" })}*:`}
            type="text"
            name="company"
            aria-label={intl.formatMessage({ id: "contact.company" })}
            onChange={handleChange}
            forwardRef={register({ required: true })}
          />
          {errors.company && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.company" })}
            />
          )}
        </StyledBox>
        <StyledBox>
          <InputText
            placeholder={`${intl.formatMessage({
              id: "contact.business_id",
            })}:`}
            type="text"
            name="business-id"
            aria-label={intl.formatMessage({ id: "contact.business_id" })}
            onChange={handleChange}
            forwardRef={register}
          />
        </StyledBox>
        <StyledBox>
          <InputText
            placeholder={`${intl.formatMessage({ id: "contact.phone" })}*:`}
            type="text"
            name="phone"
            aria-label={intl.formatMessage({ id: "contact.phone" })}
            onChange={handleChange}
            forwardRef={register({ required: true })}
          />
          {errors.phone && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.phone" })}
            />
          )}
        </StyledBox>
        <StyledBox pt={4} mb={4}>
          <TextArea
            placeholder={`${intl.formatMessage({ id: "contact.message" })}*:`}
            name="message"
            aria-label={intl.formatMessage({ id: "contact.message" })}
            onChange={handleChange}
            forwardRef={register({ required: true })}
          />
          {errors.message && (
            <ErrorMessage
              message={intl.formatMessage({ id: "contact.error.message" })}
            />
          )}
        </StyledBox>
        <Text textAlign="right">
          <FormButton type="submit">
            {intl.formatMessage({ id: "contact.send" })}
          </FormButton>
        </Text>
      </form>
    </div>
  )
}

ProductInquiryForm.propTypes = propTypes

export default injectIntl(ProductInquiryForm)
