import React from "react"
import { shape, string } from "prop-types"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const propTypes = {
  intl: shape().isRequired,
  model: string,
  measurements: shape({
    measurements: string,
  }),
  connections: shape({
    connections: string,
  }),
  code: string,
  additionalInformation: shape({
    additionalInformation: string,
  }),
  weight: string,
}

const defaultProps = {
  model: null,
  measurements: null,
  connections: null,
  code: null,
  additionalInformation: null,
  weight: null,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  padding-right: 20px;
`

const Paragraph = styled.p`
  font-size: 100%;
  @media ${({ theme }) => theme.breakpoint.mobile} {
    font-size: ${({ type }) => (type === "small" ? "100%" : "110%")} !important;
  }
  @media ${({ theme }) => theme.breakpoint.desktop} {
    font-size: ${({ type }) =>
      type === "small" ? "72.25%" : "95%"} !important;
  }
  margin-bottom: 1.5em;
`

const ProductInformationList = ({
  intl,
  model,
  measurements,
  connections,
  code,
  additionalInformation,
  weight,
}) => {
  return (
    <Wrapper>
      {model && (
        <Paragraph itemProp="mpn" type="small">
          {intl.formatMessage({ id: "product.model" })}: {model}
        </Paragraph>
      )}
      {measurements && (
        <Paragraph itemProp="width" type="small">
          {intl.formatMessage({ id: "product.measurements" })}:{" "}
          {measurements.measurements}
        </Paragraph>
      )}
      {connections && (
        <Paragraph type="small">
          {intl.formatMessage({ id: "product.connections" })}:{" "}
          {connections.connections}
        </Paragraph>
      )}
      {code && (
        <Paragraph itemProp="mpn" type="small">
          {intl.formatMessage({ id: "product.code" })}: {code}
        </Paragraph>
      )}
      {additionalInformation && (
        <Paragraph itemProp="description" type="small">
          {intl.formatMessage({ id: "product.additional_information" })}:{" "}
          {additionalInformation.additionalInformation}
        </Paragraph>
      )}
      {weight && (
        <Paragraph itemProp="weight" type="small">
          {intl.formatMessage({ id: "product.weight" })}: {weight}
        </Paragraph>
      )}
    </Wrapper>
  )
}

ProductInformationList.propTypes = propTypes
ProductInformationList.defaultProps = defaultProps

export default injectIntl(ProductInformationList)
