import React from "react"
import { arrayOf, shape } from "prop-types"
import { Flex, Box } from "rebass"
import { injectIntl } from "gatsby-plugin-intl"
import theme from "../../config/theme"
import Card from "./card"
import PageTitle from "../atoms/page-title"
import { getFallbackImageFluid } from "../../helpers/index"

const propTypes = {
  items: arrayOf(shape()).isRequired,
  allItems: arrayOf(shape()).isRequired,
  intl: shape().isRequired,
}

const RelatedItems = ({ allItems, items, intl }) => {
  return (
    <div>
      <Flex mx={-theme.gridGutter} flexWrap="wrap">
        <Box width={1} px={theme.gridGutter}>
          <PageTitle as="h2">
            {intl.formatMessage({ id: "product.related_products" })}
          </PageTitle>
        </Box>
        {items &&
          items.map(({ node: p }) => (
            <Box
              width={[1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 4]}
              px={theme.gridGutter}
              key={p.id}
            >
              <Card
                slug={p.slug}
                title={p.title}
                // fluid={p.image.fluid}
                fallbackFluid={getFallbackImageFluid(allItems, p.contentful_id)}
                body={p.body}
              />
            </Box>
          ))}
      </Flex>
    </div>
  )
}

RelatedItems.propTypes = propTypes

export default injectIntl(RelatedItems)
